/*
	This mixin is for media queries.
	Currently only for small screens i.e. mobile phones
*/
@mixin screen($size) {
	@if $size == 'small' {
		@media screen and (max-width: 768px) {
			@content;
		}
	} @else if $size == 'x-small' {
		@media screen and (max-width: 360px) {
			@content;
		}
	}
}

/*
	This mixin is for styling ul element
*/
@mixin ulStyleFilter {
	list-style: none;
	padding: 0;
}

/*
	This mixin is for declaring a new font using CSS font-face
*/
@mixin font-face($name, $path, $weight: null, $style: null, $exts: ttf otf) {
	$src: null;

	$extmods: ();

	$formats: (
		otf: 'opentype',
		ttf: 'truetype',
	);

	@each $ext in $exts {
		$extmod: if(
			map-has-key($extmods, $ext),
			$ext + map-get($extmods, $ext),
			$ext
		);
		$format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
		$src: append(
			$src,
			url(quote($path + '.' + $extmod)) format(quote($format)),
			comma
		);
	}

	@font-face {
		font-family: $name;
		font-style: $style;
		font-weight: $weight;
		src: $src;
	}
}

/*
	This mixin will declare a keyframe with all the required vendor prefixes
*/
@mixin keyframes($animation-name) {
	@-webkit-keyframes #{$animation-name} {
		@content;
	}

	@-moz-keyframes #{$animation-name} {
		@content;
	}

	@-o-keyframes #{$animation-name} {
		@content;
	}

	@keyframes #{$animation-name} {
		@content;
	}
}

@mixin animation($animation-name) {
	-webkit-animation: #{$animation-name};
	-moz-animation: #{$animation-name};
	-ms-animation: #{$animation-name};
	-o-animation: #{$animation-name};
	animation: #{$animation-name};
}
