@import '../../../styles/index.scss';

.switch__container {
	input {
		display: none;
	}

	input:checked + label .ball {
		transform: translateX(2.7rem);
	}

	label {
		display: flex;
		width: $theme-switch-width;
		height: $theme-switch-height;
		justify-content: space-between;
		align-items: center;
		padding: $theme-switch-padding-vert $theme-switch-padding-horiz;
		border-radius: 5rem;
		cursor: pointer;
		position: relative;

		@include themify() {
			background: themed(theme-switch-bg);
		}

		.ball {
			position: absolute;
			display: block;
			width: $theme-switch-ball-size;
			height: $theme-switch-ball-size;
			top: 0.5rem;
			left: 0.5rem;
			background: #fff;
			border-radius: 50%;
			transition: 0.8s;
		}

		i {
			font-size: $icon-font-size;
			.icon-sun {
				color: gold;
				transition: 0.8s;
			}

			.icon-moon {
				color: black;
				transition: 0.8s;
			}
		}
	}
}
