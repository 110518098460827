@import '../../../styles/theme.scss';

.addtags__container {
	display: flex;
	flex-direction: column;
	gap: 16px;

	&--selected {
		display: flex;
		gap: 4px;
		margin: 0 !important;

		.tag__item {
			margin: 0 !important;
			display: inline-block;
			padding: 4px 10px;
			border: 1px solid grey;
			border-radius: 8px;

			&--name {
				margin: 0;
			}
		}
	}

	&--input {
		position: relative;
		margin: 0 !important;

		.dropdown__container {
			margin: 0 !important;
			position: absolute;
			z-index: 10;
			background-color: white;
			width: 100%;
			box-shadow: 0 4px 4px rgba(165, 165, 165, 0.5);

			&--item {
				margin: 0 !important;
				padding: 8px 12px;
				cursor: pointer;

				&:hover {
					background-color: lightgrey;
				}
			}
		}
	}
}
