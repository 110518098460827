@import './styles/index.scss';

// @include font-face(Rye, "../fonts/Rye/Rye-Regular", normal, normal, ttf);
// @include font-face(Inter, "../fonts/Inter/Inter-Regular", normal, normal, ttf);

*,
*::before,
*::after {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}
html {
	font-size: 62.5%;
	// when the root font-size is 100%, then by default the font-size is 16px.
	// if we set the root font-size to 62.5%, then the default font-size becomes 62.5% of 16px = 10px
}

body {
	font-family: 'Inter', sans-serif;
}

*::selection {
	@include themify() {
		color: themed(text-selection-color);
		background-color: themed(text-bg-color);
	}
}

@include screen('small') {
	html {
		font-size: 50%;
	}
}
