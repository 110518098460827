@import '../../../styles/index.scss';

.blog__section {
	flex: 1;
	display: flex;
	flex-direction: column;

	.blog__container {
		flex: 1;
		display: flex;
		flex-direction: column;
	}
}
