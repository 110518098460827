@import '../../../../styles/index.scss';

.modal__background {
	width: 100%;
	height: 100%;
	position: absolute;
	background-color: rgba(0, 0, 0, 0.2);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
}
.modal__container {
	height: 60%;
	width: 60%;
	position: fixed;
	box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11),
		0 5px 15px 0 rgba(0, 0, 0, 0.08);
	overflow-y: auto;
	background-color: $white;
	color: $black;
}
