@import '../../../styles/index.scss';

.blog__cms {
	height: 100vh;
	display: flex;
	flex-direction: column;

	*::selection {
		color: $white !important;
		background-color: $black !important;
	}
}
