@import '../../../../styles/index.scss';

.cmspost__container {
	width: 70%;
	height: 100%;
	max-width: 900px;
	align-self: center;
	padding: 2rem;
	color: $black;

	code {
		padding: 0.2rem 0.4rem;
		margin: 0;
		color: $color-accent;
		background-color: rgba(203, 1, 99, 0.05);
		border-radius: 0.4rem;
	}

	ul,
	ol {
		padding: 0 4rem;
		display: grid;
		gap: 1rem;
	}

	.not-found {
		font-size: 1.6rem;
	}
	.cms_post {
		.post__body {
			margin: 6rem 0;
			font-size: 1.6rem;
			line-height: 1.5;
			letter-spacing: 0.01rem;

			.tags__container {
				margin-bottom: 32px;
				display: flex;
				flex-direction: row;

				&--list {
					display: block;
					list-style: none;
					padding: 0;

					.list_item {
						&:hover {
							color: black;
						}
					}
				}

				&--label {
					display: inline-block;
					margin-right: 20px;
				}
			}
		}
		.post__heading {
			display: flex;
			flex-direction: row;
			align-items: center;
			gap: 3rem;

			&--edit {
				font-size: 2rem;
				align-self: center;
				cursor: pointer;
			}

			&--body {
				flex: 1;

				.title {
					font-size: 4rem;
					font-weight: bold;
				}
				.desc {
					font-size: 2rem;
					padding: 0.5rem 0 0 0.2rem;
				}
			}
			&--date {
				display: flex;
				flex-direction: column;
				align-items: center;

				.date {
					font-size: 4rem;
				}
				.month,
				.year {
					font-size: 1.6rem;
				}
			}
		}
	}
}
