@import '../../../styles';

.home__container {
	height: 100vh;
	position: relative;

	.component_circle {
		&:after {
			content: "";	
			position: absolute;
			height: 600px;
			width: 600px;
			border-radius: 50%;
			top: -100px;
			left: -300px;
			background: radial-gradient(circle at center,  #cb016363 40%, #cb016328 80%, #cb0163bb 80%);
			filter: blur(72px);
		}
	}

	.content__container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 2rem;

		&--img {
			height: 15rem;
			width: 15rem;
			border-radius: 50%;
			background-size: cover;
			background-position: center center;
		}

		&--title {
			text-align: center;
			span {
				display: block;
			}
			@include themify() {
				color: themed(text-color);
			}

			.primary {
				font-family: 'Rye', cursive;
				font-size: $home-primary-title;
			}

			.secondary {
				font-size: $home-secondary-title;
			}
		}
	}
}

@include screen('small') {
	.home__container {
		padding: 1rem 2.4rem;
	}
}
