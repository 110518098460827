@import '../../../styles';

.navbar__container {
	min-height: 40px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;

	@include themify() {
		color: themed(text-color);
	}

	&--logo {
		span {
			font-weight: bold;
			text-transform: uppercase;
			font-size: $navbar-logo;
		}
	}

	&--actions {
		user-select: none;

		ul > li:hover {
			cursor: pointer;
		}

		.normal {
			ul {
				list-style: none;

				li {
					display: inline-block;
					margin: 2px 10px;
					font-size: $navbar-actions;

					a {
						text-decoration: none;
						@include themify() {
							color: themed(text-color);
						}
					}
					&:hover {
						text-decoration: underline;
					}
				}
			}
		}

		.responsive {
			display: none;

			&__controls {
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: center;
				gap: 20px;
			}
			.icon-bars {
				font-size: 3rem;
				@include themify() {
					color: themed(text-color);
				}
			}

			.navbar__responsive {
				overflow-x: hidden;
				position: fixed;
				height: 100vh;
				width: 100%;
				right: 0;
				top: 0;
				z-index: 10;
				background-color: rgba(129, 129, 129, 0.5);


				.responsive_right {
					width: 50%;
					height: 100vh;
					float: right;
					
					@include themify() {
						background-color: themed(color-accent);
					}
				}

				&--close {
					padding: 20px;
					color: $white;
					font-size: 3rem;
					display: flex;
					flex-direction: row;
					justify-content: flex-end;

					.btn-close {
						width: 17px;
						height: 24px;
					}
				}

				ul {
					margin-top: 4rem;
					@include ulStyleFilter;

					li {
						padding: 10px 20px;

						a {
							padding-bottom: 8px;
							margin-right: 32px;
							display: block;
							border-bottom: 1px solid $white;
							color: $white;
							text-decoration: none;
							font-size: 2rem;
						}
					}
				}
			}
		}
	}
}

@include screen('small') {
	.navbar__container {
		&--actions {
			.normal {
				display: none;
			}

			.responsive {
				display: block;
			}
		}
	}
}
