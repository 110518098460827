@import '../../../styles/index.scss';

.container {
	overflow: hidden;
	min-height: 100vh;
	transition: background-color 0.8s;
	display: flex;
	flex-direction: column;
	@include themify() {
		background-color: themed(main-bg);
	}

	.page-content {
		display: flex;
		flex-direction: column;
		width: 70%;
		max-width: 900px;
		margin: auto;
		height: 100%;
		flex: 1;
	}
}

@include screen('small') {
	.container {
		.page-content {
			width: 100%;
		}
	}
}
