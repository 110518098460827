@import '../../../../styles/index.scss';

.addnew__container {
	width: 70%;
	max-width: 900px;
	align-self: center;
	padding: 2rem;

	color: $black;

	.error {
		margin: 0.5rem 0;
		color: red;
		font-size: 1.6rem;
	}
	form {
		font-size: 1.6rem;
		div {
			margin: 1.4rem 0;
			label {
				display: block;
			}
			input,
			textarea {
				margin: 1.4rem 0;
				display: block;
				width: 100%;
				padding: 1.2rem 1.5rem;
				outline: none;
				border: 0.1rem solid #eee;
				border-radius: 0.4rem;

				&:focus,
				&:active {
					outline: none;
					border: 0.1rem solid $color-accent;
				}
			}
		}
		.input-content {
			min-height: 30rem;
		}
		.format__input {
			min-height: 15rem;
		}
		.btns {
			display: flex;
			flex-direction: row;
			gap: 1rem;
		}
	}
}
