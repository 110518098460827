@import "../../../styles/theme.scss";

.marquee__container {
    font-family: 'Inter', sans-serif;

    .marquee-text {
        font-weight: bold;

        @include themify() {
            color: themed(text-color);
        }

        &.is-outline {
            -webkit-text-stroke-width: 1px;

            @include themify() {
                color: themed(text-selection-color);
                -webkit-text-stroke-color: themed(text-color);
            }
        }
    }

    &--left {
        font-size: 62px;
    }

    &--right {
        font-size: 56px;
    }
}