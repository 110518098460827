@import '.././../../styles/theme.scss';

.list_item {
	font-size: 1.4rem;
	text-align: center;
	margin: 4px 6px;
	display: inline-block;
	padding: 2px 12px;
	border-radius: 8px;
	cursor: pointer;

	&:hover {
		@include themify() {
			color: themed(text-color);
			background-color: transparent;
		}
	}

	@include themify() {
		color: white;
		background-color: themed(color-accent);
	}
}
