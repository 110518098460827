@import '../../../../styles/index.scss';

.projects__container {
	flex: 1;
	margin: 5rem 0;
	padding: 0 2rem;
	font-size: 1.6rem;

	ul {
		list-style: none;
		display: grid;
		gap: 1rem;

		& li::before {
			content: '\2022';
			font-weight: bold;
			display: inline-block;
			width: 1em;
			margin-left: -0.4rem;
			@include themify() {
				color: themed(text-color);
			}
		}
	}
	a {
		text-decoration: underline;
		text-underline-offset: 0.5rem;
		line-height: 2;
		@include themify() {
			color: themed(text-color);
		}

		&:hover {
		}
	}
}
