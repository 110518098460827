@import '../../../styles/index.scss';

.post__image {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	user-select: none;

	img {
		width: 90%;
	}

	&--desc {
		color: rgba(0, 0, 0, 0.8);
		font-size: 1.4rem;
		font-style: italic;
	}
}
