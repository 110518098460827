.loading {
	text-align: center;
	.lds-spinner div:after {
		content: ' ';
		display: block;
		position: absolute;
		top: 12px;
		left: 31px;
		width: 3px;
		height: 12px;
		border-radius: 20%;
	}
}

.loadingScroller {
	display: inline-block;
	font-size: 1em;
	height: 1em;
	line-height: 0.7;
	margin: 0.1em;
	animation: anim-rotate 1s infinite linear;
	color: #c2c2c2;
	margin-left: 0px;
	text-shadow: 0 0 0.25em rgba(255, 255, 255, 0.3);
}

.notifyLoader {
	display: inline-block;
	.lds-spinner {
		vertical-align: middle;
		margin-top: -30px;
		height: 10px;
	}
	.lds-spinner div:after {
		background: #fff;
	}
}

@keyframes anim-rotate {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

.loadingScroller:hover {
	text-decoration: none !important;
}

.lds-spinner {
	text-align: center;
	color: black;
	display: inline-block;
	position: relative;
	width: 64px;
	height: 64px;
	bottom: 10px;
}
.lds-spinner div {
	transform-origin: 32px 32px;
	animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
	content: ' ';
	display: block;
	position: absolute;
	top: 21px;
	left: 31px;
	width: 2px;
	height: 6px;
	border-radius: 20%;
	background: #737373;
}
.lds-spinner div:nth-child(1) {
	transform: rotate(0deg);
	animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
	transform: rotate(30deg);
	animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
	transform: rotate(60deg);
	animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
	transform: rotate(90deg);
	animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
	transform: rotate(120deg);
	animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
	transform: rotate(150deg);
	animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
	transform: rotate(180deg);
	animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
	transform: rotate(210deg);
	animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
	transform: rotate(240deg);
	animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
	transform: rotate(270deg);
	animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
	transform: rotate(300deg);
	animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
	transform: rotate(330deg);
	animation-delay: 0s;
}
@keyframes lds-spinner {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
