@import '../../../styles/index.scss';

.posts__card {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	gap: 1rem;
	font-size: 1.8rem;
	margin: 1rem 0;

	@include themify() {
		color: themed(text-color);
	}

	&--date {
		width: 20%;
		padding: 1rem 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		span {
			display: block;
		}
		.date {
			font-family: 'Rye', serif;
			font-size: 4rem;
		}

		.month {
			font-size: 1.6rem;
		}

		.year {
			font-size: 1.4rem;
		}
	}

	&--desc {
		flex: 1;
		padding: 1rem 2rem;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		.title {
			color: $color-accent;
			font-size: 2.4rem;
			font-weight: bold;
			margin-bottom: 1rem;
			cursor: pointer;

			&:hover {
				text-decoration: underline;
			}
		}

		.desc {
			font-size: 1.8rem;
		}
	}
	@include themify() {
		color: themed(text-color);
	}
}
