@import '../../../styles/index.scss';

.footer__container {
	margin-top: 10rem;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	background-color: $color-accent;
	padding: 0.8rem 1rem 2rem 1rem;

	&--links {
		display: flex;
		flex-direction: row;
		gap: 2rem;
		color: $white;
		font-size: 1.6rem;

		a {
			text-decoration: none;
			color: #fff;
		}

		.icon {
			transition: color 0.2s;
			&:hover {
				color: $black;
			}
		}
	}

	&--body {
		font-size: 1.4rem;
		color: $white;

		a {
			text-decoration: underline;
			color: white;

			&:hover {
				text-decoration: none;
			}
		}
	}
}
