@import '../../../styles/index.scss';

.scrolltop__container {
	position: fixed;
	bottom: 30px;
	right: 30px;
	animation-name: fade-in;
	animation-duration: 0.6s;
	animation-timing-function: ease-in;

	&:active {
		transform: translateY(3px);
	}

	&--icon {
		height: 50px;
		width: 50px;
		border-radius: 50%;
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;

		font-size: $scrolltop-font-size;

		@include themify() {
			background-color: themed(text-color);
			color: themed(main-bg);
		}
	}
}
