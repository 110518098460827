@import '../../../styles/index.scss';

.socials__container {
	text-align: center;
	margin-top: 3rem;

	&--title {
		font-size: 1.6rem;
	}

	&--links {
		a {
			text-decoration: none;
			@include themify() {
				color: themed(text-color);
			}
		}

		.icon {
			font-size: 7rem;
			padding: 1rem 2rem;
		}
		.icon:hover {
			color: $color-accent;
		}
	}
}
