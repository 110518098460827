$black: black;
$white: white;
$color-accent: #cb0162;
$orange: #f5b041;
$dark-orange: #d35400;

$themes: (
	dark: (
		main-bg: $black,
		text-color: $white,
		text-selection-color: $black,
		text-bg-color: $white,
		theme-switch-bg: $color-accent,
		color-accent: $color-accent,
		code-color: $orange,
	),
	light: (
		main-bg: $white,
		text-color: $black,
		text-selection-color: $white,
		text-bg-color: $black,
		theme-switch-bg: $color-accent,
		color-accent: $color-accent,
		code-color: $dark-orange,
	),
);
