@import '../../../styles/index.scss';

.blog__header {
	min-height: 16rem;
	width: 100%;

	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	gap: 1rem;
	transition: background-color 0.8s;

	@include themify() {
		background-color: themed(text-color);
		color: themed(main-bg);
	}

	.header__title {
		padding: 0px 1rem;
		span {
			display: block;
		}

		& span::selection {
			@include themify() {
				background-color: themed(main-bg) !important;
				color: themed(text-color) !important;
			}
		}

		&--primary {
			font-size: 4rem;
			font-weight: bold;
		}

		&--secondary {
			font-size: 1.8rem;
		}
	}

	.header__actions {
		width: 100%;
		min-height: 4.5rem;
		background-color: $color-accent;
		align-self: flex-end;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		align-items: center;
		font-size: 1.8rem;

		&--theme {
			margin-right: 1rem;
		}
		&--nav {
			ul {
				@include ulStyleFilter;

				li {
					display: inline-block;
					padding: 0.2rem 1rem;
					color: $white;

					&:hover {
						text-decoration: underline;
						cursor: pointer;
					}

					a {
						text-decoration: none;
						color: $white;
					}
				}
			}
		}
	}
}
