@import '../../../../styles/index.scss';

.login__container {
	width: 70%;
	max-width: 900px;
	align-self: center;
	padding: 2rem;

	color: $black;

	&--form {
		.form_title {
			font-size: 2.4rem;
			font-weight: bold;
		}

		.form_error {
			font-size: 1.4rem;
			color: red;
		}

		.form_email,
		.form_password {
			font-size: 1.6rem;
			width: 300px;
			margin: 1rem 0;
			label {
				display: block;
				padding: 0.8rem 0;
			}
			input {
				width: 100%;
				padding: 1rem 1.5rem;
				display: block;
				border-radius: 0.5rem;
				outline: none;
				border: 2px solid $black;

				&:active,
				&:focus {
					outline: none;
					border: 2px solid $color-accent;
				}
			}
		}

		.btn_login {
			margin: 1rem 0;
		}
	}
}
