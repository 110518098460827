@import '../../../../styles/index.scss';

.blogs__container {
	width: 70%;
	height: 100%;
	max-width: 900px;
	align-self: center;
	padding: 2rem;
	color: $black;

	.admin__posts {
		color: $black;
	}

	.spinner-loader {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}
