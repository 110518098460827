@import '../../../styles/index.scss';

.image__container {
	max-width: 150px;
	position: relative;
	box-shadow: 0.2rem 0.6rem 0.8rem rgba(0, 0, 0, 0.2);

	img {
		height: 15rem;
		width: 15rem;
	}

	.copy {
		display: none;
	}

	&--overlay {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.3);
		color: $white;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

		div {
			border: 2px solid $white;
			border-radius: 4rem;
			padding: 0.2rem 1rem;
			color: $white;
			cursor: pointer;
		}
	}
}
