.policy__container {
    font-size: 16px;
    padding: 28px 16px;
    margin: 10px;

    .strong {
        font-weight: bold;
        font-size: 24px;
        margin: 10px 0;
    }
}