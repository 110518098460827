@import '../styles/index.scss';

.hidden {
	display: none;
}

@include keyframes(fade-in) {
	0% {
		opacity: 0;
	}
	50% {
		opacity: 0.5;
	}
	90% {
		opacity: 1;
	}
}

@include keyframes(fade-out) {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
	90% {
		opacity: 0;
	}
}
