@import '../../../styles/index.scss';

.button {
	padding: 1rem 2rem;
	outline: none;
	border: none;
	border-radius: 0.4rem;
	box-shadow: 0.2rem 0.8rem 1rem rgba(0, 0, 0, 0.2);
	cursor: pointer;

	&:active {
		transform: translateY(0.3rem);
		box-shadow: 0rem 0rem 1rem rgba(0, 0, 0, 0.2);
	}
}
