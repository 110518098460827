@import '../../../../styles/index.scss';

.post__container {
	flex: 1;
	padding: 1rem 2rem;

	@include themify() {
		color: themed(text-color);
	}

	&--header {
		margin-top: 4rem;
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 2rem;
		min-height: 10rem;

		span {
			display: block;
		}

		.header-date {
			text-align: center;
			min-width: 10%;
		}

		.date {
			font-family: Rye, 'cursive';
			font-size: 3.5rem;
		}

		.month,
		.year {
			font-size: 1.6rem;
		}

		.header-body {
			.title {
				font-size: 4rem;
				font-weight: bold;
				color: $color-accent;
			}
		}
	}

	&--body {
		margin-top: 5rem;
		font-size: 1.6rem;
		line-height: 1.5;
		letter-spacing: 0.01rem;

		.tags__container {
			margin-bottom: 32px;
			display: flex;
			flex-direction: row;

			&--list {
				display: block;
				list-style: none;
				padding: 0;
			}

			&--label {
				display: inline-block;
				margin-right: 20px;
			}
		}

		code {
			padding: 0.2rem 0.4rem;
			margin: 0;
			border-radius: 0.4rem;
		}

		ul,
		ol {
			padding: 0 4rem;
			display: grid;
			gap: 1rem;
		}

		a,
		a:active {
			color: $color-accent;
			text-decoration: none;
			&:hover {
				text-decoration: underline;
			}
		}
	}

	.spinner-loader {
		flex: 1;
		align-self: center;
		margin-top: 10rem;
	}
}

.theme--light {
	.post__container code {
		color: $color-accent;
		background-color: rgba(203, 1, 99, 0.05);
	}
}

.theme--dark {
	.post__container code {
		color: $white;
		background-color: rgba(203, 1, 99, 0.5);
	}
}

@include screen('x-small') {
	.post__container {
		&--header {
			flex-direction: column-reverse;
			justify-content: center;
			align-items: flex-start;
			gap: 1rem;

			.header-date {
				.date,
				.month,
				.year {
					font-size: 1.6rem;
					@include themify() {
						color: themed(text-color);
					}
				}
				span {
					padding: 0 0.2rem;
					display: inline-block;
				}

				.date {
					font-family: 'Inter', sans-serif;
				}
			}
			.header-body {
				.title {
				}
			}
		}
	}
}
