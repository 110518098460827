$navbar-logo: 2.2rem;
$navbar-actions: 1.6rem;

$home-primary-title: 4rem;
$home-secondary-title: 2rem;

$theme-switch-width: 6rem;
$theme-switch-height: 3.5rem;
$theme-switch-padding-horiz: 0.6rem;
$theme-switch-padding-vert: 0rem;
$theme-switch-ball-size: 2.5rem;

$icon-font-size: 1.8rem;
$social-icons-font-size: 4rem;
$scrolltop-font-size: 2.6rem;

$normal-text-size: 1.6rem;
