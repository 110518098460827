@import '../../../styles/index.scss';

.blogcms__header {
	width: 100%;
	background-color: $color-accent;
	display: flex;
	flex-direction: column;

	.header__top {
		width: 70%;
		max-width: 90rem;
		margin: auto;

		min-height: 7rem;
		display: flex;
		padding: 1rem 2rem;
		flex-direction: row;
		justify-content: space-between;
		align-items: flex-end;

		&--logo {
			font-size: 3rem;
			color: $white;
			font-weight: bold;
		}
	}

	.header__bottom {
		width: 100%;
		background-color: $black;

		&--links {
			width: 70%;
			max-width: 90rem;
			margin: auto;

			min-height: 4rem;
			display: flex;
			flex-direction: row;
			align-items: center;
			background-color: $black;
			font-size: 1.8rem;

			ul {
				@include ulStyleFilter;

				li {
					display: inline-block;
					padding: 1.6rem;
				}
			}

			a {
				text-decoration: none;
				color: $white;

				&:hover {
					text-decoration: underline;
					cursor: pointer;
				}
			}

			div {
				color: $white;

				&:hover {
					text-decoration: underline;
					cursor: pointer;
				}
			}
		}
	}
}
