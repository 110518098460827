@import '../../../../styles/index.scss';

.posts__container {
	flex: 1;
	display: flex;
	flex-direction: column;
	height: 100%;

	.spinner-loader {
		flex: 1;
		align-self: center;
		margin-top: 10rem;
	}
}
