@import '../../../styles/index.scss';

.about__container {
	height: 100vh;
	position: relative;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	.component_circle {
		&:after {
			content: "";	
			position: absolute;
			height: 600px;
			width: 600px;
			border-radius: 50%;
			bottom: 100px;
			right: -300px;
			background: radial-gradient(circle at center,  #646cff62 40%, #646cff3f 80%, #646cff 80%);
			filter: blur(72px);
		}
	}

	@include themify() {
		color: themed(text-color);
	}

	&--desc {
		font-size: $normal-text-size;
		text-align: center;

		code {
			font-weight: 600;
			@include themify() {
				color: themed(code-color);
			}
		}
	}
}

@include screen('small') {
	.about__container {
		padding: 1rem 2.4rem;
	}
}
