@import "../../../styles/theme.scss";

.audionote__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 24px 0;
    width: auto;
    color: #000;

    .label {
        font-size: 14px;
        font-style: italic;
    }
}