@import '../../../styles/index.scss';

.notfound__container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 10rem;

	span {
		display: block;
		text-align: center;
	}

	&--title {
		font-size: 4rem;
		font-weight: bold;
	}

	&--body {
		font-size: 1.6rem;
	}

	@include themify() {
		color: themed(text-color);
	}
}
