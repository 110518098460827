@import '../../../../styles/index.scss';

.workstation__container {
	flex: 1;
	font-size: 1.6rem;

	&--img {
		margin: 24px 0;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		gap: 24px;

		.img-block {
			flex: 1;
			max-width: 40%;
			display: flex;
			flex-direction: column;
			align-items: center;

			.label {
				font-size: 12px;
				margin-top: 6px;
				font-style: italic;
			}

			img {
				max-width: 100%;
			}
		}
	}

	@include themify() {
		color: themed(text-color);
	}

	.title {
		margin-top: 20px;
	}

	.table-container {
		margin: 36px 0;

		.table {
			width: 100%;
			.table-header {
				&-row {
					&-cell {
						height: 50px;
						border: 2px solid;

						@include themify() {
							border-color: themed(text-color);
						}
					}
				}
			}
			.table-body {
				&-row {
					&-cell {
						padding: 20px;
						height: 40px;
						border: 1px solid;

						@include themify() {
							border-color: themed(text-color);
						}

						div {
							span {
								display: block;
							}
						}
					}
				}
			}
		}
	}
}
