@import '../../../styles/index.scss';

.copybar__container {
	display: flex;
	flex-direction: row;
	align-content: center;
	width: 100%;
	margin: 1rem 0;

	input {
		flex: 1;
		padding: 1rem 1.5rem;
		border-radius: 0.5rem 0 0 0.5rem;
		outline: none;
		border: none;
		border: 0.2rem solid $black;
		border-color: $black transparent $black $black;

		&::selection {
			background-color: $black;
			color: $white;
		}
	}

	.icon-copy {
		display: flex;
		align-items: center;
		background-color: lightblue;
		padding: 0.4rem 1rem;
		font-size: 2rem;
		color: $white;
		border-radius: 0 0.5rem 0.5rem 0;
		border: 2px solid black;
		border-color: $black $black $black transparent;

		&:hover {
			background-color: blue;
		}
	}
}
